<template>
    <div class="login-form">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(login)">
            <ValidationProvider  class="input-login"  rules="required|max:50" v-slot="{ errors }">
              <label>{{ $t('form.password') }}</label>
              <input type="password" ref="pwd" :class="{ 'input-error' : errors[0]}"  v-model="password" />
              <span class="notice-error">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="input-submit">
              <input type="submit" class="btn-form" :value="$t('form.connexion')" />
            </div>
          </form>
        </ValidationObserver>
        <div class="pwd-lost" @click="displayModal = true">{{ $t('form.passwordLost') }}</div>
        <ModalConfirm @close="displayModal = false" :loader="displayLoader" @confirm="passwordReset" v-if="displayModal" :contentModal="$t('form.passwordReset', { email : email } )"></ModalConfirm>
      </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import ModalConfirm from './../ModalConfirm';
import axios from 'axios'
import store from './../../store'
import router from './../../router'

export default {
    name:'PasswordVerify',
    components : {
      ValidationProvider,
      ValidationObserver,
      ModalConfirm
    },
    props: ["email"],
    data() {
      return {
        password : null,
        displayModal : false,
        displayLoader : false
      }
    },
    methods : {
        login() {        
          let params = {
            email : this.email,
            password : this.password
          }

          let config = {
            validateStatus: () => true,
          };

          axios
            .post(store.getters.getURL +'login', params, config)
            .then(function (response) {
              if(response.status === 200) {
                store.commit('setToken', response.data.token)
                store.commit('login', response.data)
                if(response.data.role === 1) {
                  router.push({ name : 'UsersList'});
                }else if(response.data.role === 2) {
                  router.push({ name : 'UsersListCoordinator'});
                }else if(response.data.role === 3) {
                  router.push({ name : 'UsersListDoctor'});
                }else if(response.data.role === 4) {
                  router.push({ name : 'PathologyList'});
                }
              } else {
                store.dispatch('addNotif', {type : 2, content : 8})
              }
            })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
        },
        passwordReset() {
          let config = {
            validateStatus: () => true,
          };

          this.displayLoader  = true

          let params = {
            email : this.email,
          }

          let vm = this

          axios
            .post(store.getters.getURL +'password/forgot', params, config)
            .then(function (response) {
              vm.displayLoader = false
              if(response.status == 200) {
                vm.displayModal = false
                store.dispatch('addNotif', {type : 1, content : 5})
              }else {
                store.dispatch('addNotif', {type : 2, content : 6})
              }
            })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
        }

    },
    mounted() {
      this.$refs.pwd.focus();
    }
}
</script>

<style lang="scss" scoped>
 .input-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    margin-bottom:20px;
  }

  .input-login label {
    font-size:18px;
    color:$inputBackgroundLogin;
    margin-bottom:5px;
    font-family: $font;
  }

  .input-login input {
    width:calc(100% - 40px);
    padding:10px 20px;
    background:$inputBackgroundLogin;
    border-radius:10px;
    font-size:15px;
    color:$textColor;
  }

  .pwd-lost {
      margin-top:20px;
      color:$mainColor;
      text-align: center;
      font-family: $font;
      font-size:15px;
  }

  .pwd-lost:hover {
      cursor:pointer;
      text-decoration: underline;
  }
</style>