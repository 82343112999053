<template>
    <div class="main">
      <div class="logo-container">
        <img src="./../assets/logo-remedex.png" alt="logo Remedex" />
      </div>
      <EmailVerify v-if="state === 1 && !isLoading" @emailVerify="emailVerify" ></EmailVerify>
      <PasswordVerify v-if="state === 2" :email="email" ></PasswordVerify>
      <div class="first" v-if="state === 3 && firstConnexion">
        <p v-if="firstConnexion === 1 " class="first-success" v-html="$t('password.firstConnection')"></p>
        <p v-else class="first-failed">{{ $t('password.firstConnectionFailed')}}</p>
        <!-- <div class="btn" @click="state = 1">{{ $t('form.connexion')}}</div> -->
      </div>
      <Loader v-if="isLoading" ></Loader>
    </div>
</template>

<script>
import EmailVerify from './../components/login/EmailVerify'
import PasswordVerify from './../components/login/PasswordVerify'
import store from './../store'
import axios from 'axios'
import Loader from './../components/Loader'

export default {
    name:'Login',
    components : {
      EmailVerify,
      PasswordVerify,
      Loader
    },
    data() {
      return {
        email : null,
        state : 1,
        firstConnexion :null,
        isLoading : false
      }
    },
    methods : {
      emailVerify(e) {        
        //Call API pour savoir si 1ere connexion
        this.email = e.email
         this.isLoading = true
        if(e.first_connexion === 1 ) {
          this.state = 3
          
          //envoi mail reset
          let config = {
            validateStatus: () => true,
          };

          let params = {
            email : this.email,
          }

          let vm = this

          axios
            .post(store.getters.getURL +'password/forgot', params, config)
            .then(function (response) {
              vm.isLoading = false
              if(response.status == 200) {
                vm.firstConnexion = 1
              }else {
                vm.firstConnexion = 2
              }
            })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
        } else {
          this.isLoading = false
          this.state = 2
        }
        
      }
    },
    mounted() {
      store.commit('logout')
    }
}
</script>

<style lang="scss" scoped>

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom:80px;
  }

  .logo-container img {
    width: 300px;
    height:auto;
    max-width: 70%;
  }

  .first {
    width : 600px;
    max-width: 90%;
    margin: 0 auto;
  }

  .first-success {
    color:$success;
    text-align: center;
  }

  .first-failed {
    color:$error;
    text-align: center;
  }

  .btn {
    width:200px;
    max-width: 90%;
    margin: 20px auto;
  }
 
 
</style>