<template>
    <div class="login-form">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(emailForm)">
            <ValidationProvider  class="input-login"  rules="required|max:100|email" v-slot="{ errors }">
              <label>{{ $t('form.login') }}</label>
              <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" />
              <span class="notice-error">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="input-submit">
              <input type="submit" class="btn-form" :value="$t('form.next')" />
            </div>
          </form>
        </ValidationObserver>
        
      </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../../store'


export default {
    name:'EmailVerify',
    components : {
      ValidationProvider,
      ValidationObserver
    },
    data() {
      return {
        email : null
      }
    },
    methods : {
      emailForm() {        
        //Call API pour savoir si 1ere connexion
        let vm = this
        let params = {
          email : this.email
        }    

        let config = {
          validateStatus: () => true,
          //headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        };

        axios
          .post(store.getters.getURL +'is_first_connection', params, config)
          .then(function (response) {
            if(response.status === 200) {
              //stockage du cookie
              let expireDate = new Date(Date.now() + 86400000 * 7) //7 jours
              document.cookie = 'emailRemedex=' + vm.email +';expires=' + expireDate
              vm.$emit('emailVerify', {email : vm.email, first_connexion : response.data.first_connexion}) 
            } else {
              store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
            }
          })
          .catch((res) => {
              store.dispatch('addNotif', {type : 2, content : res})
          });
       
      }
    },
    mounted() {
      const getCookies = document.cookie;
      let cookies = getCookies.split(';')
      cookies = cookies.find(e => e.includes('emailRemedex=') )
      if(cookies) {
        this.email = cookies.split('; ')
                      .find(e => e.startsWith('emailRemedex='))
                      .split('=')[1];
      }
    }
}
</script>

<style lang="scss" scoped>
 .input-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    margin-bottom:20px;
  }

  .input-login label {
    font-size:18px;
    color:$inputBackgroundLogin;
    margin-bottom:5px;
    font-family: $font;
  }

  .input-login input {
    width:calc(100% - 40px);
    padding:10px 20px;
    background:$inputBackgroundLogin;
    border-radius:10px;
    font-size:15px;
    color:$textColor;
  }
</style>